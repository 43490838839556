import {
  defaultLocaleIdentifier,
  IAllGraphqlRootPages,
  IGraphqlAllRegionCountryLanguageAssignment,
  IGraphqlLocaleIdentifier,
} from '@bemer/base';
import {
  AppCenteredSpinner,
  AppHelmet,
  VisitorSettings,
} from '@bemer/web-app-base';
import { graphql } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

interface IPropsRootPage {
  data: {
    allRegionCountryLanguageAssignments: IGraphqlAllRegionCountryLanguageAssignment;
    allRootPages: IAllGraphqlRootPages;
  };
}

const Home = ({
  data: { allRegionCountryLanguageAssignments, allRootPages },
}: IPropsRootPage): JSX.Element => {
  console.info(
    '[marketing-site]',
    'allRootPages',
    JSON.stringify(allRootPages, null, 2)
  );
  const pageId = allRootPages.edges[0]?.node._id;
  const pageSearchString =
    pageId.indexOf('___') === -1
      ? pageId
      : pageId.substring(0, pageId.indexOf('___'));

  // possible TODO: make this a constant to import?
  const allActiveLocaleIdentifiers: IGraphqlLocaleIdentifier[] = [];
  allRegionCountryLanguageAssignments.edges.map((region) =>
    region.node.countries.map((country) =>
      country.localeIdentifiers.map((localeIdentifier) =>
        allActiveLocaleIdentifiers.push(localeIdentifier)
      )
    )
  );
  return (
    <QueryClientProvider client={queryClient}>
      <VisitorSettings
        allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
        doPartnerHandleRedirect
      />

      <AppHelmet
        title={allRootPages.edges[0]?.node.title}
        allActiveLocaleIdentifiers={allActiveLocaleIdentifiers}
        pageContext={{
          _id: allRootPages.edges[0]?.node._id,
          localeIdentifier: allActiveLocaleIdentifiers.length
            ? allActiveLocaleIdentifiers[0]
            : defaultLocaleIdentifier,
          doPartnerHandleRedirect: true,
          pageSearchString: `*${pageSearchString}*`,
        }}
      />

      <AppCenteredSpinner />
    </QueryClientProvider>
  );
};

const query = graphql`
  {
    allRegionCountryLanguageAssignments: allRegionCountryLanguageAssignment {
      edges {
        node {
          regionCode
          regionName
          countries {
            countryCode
            countryName
            isRegionMaster
            localeIdentifiers {
              active
              default
              isRegionMasterPrimaryLanguage
              languageCode
              languageName
              localeId
              country {
                countryCode
                countryName
                isRegionMaster
                region {
                  regionCode
                  regionName
                }
              }
            }
          }
        }
      }
    }
    allRootPages: allSanityRootPage {
      edges {
        node {
          ...RootPage
        }
      }
    }
  }
`;

export { Home as default, query };
